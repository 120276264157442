<template>
  <div class="amp-body">
    <TheHeader />

    <slot />

    <TheSidebarContainer />

    <!-- .footer - нужен для amp-next-page. -->
    <div class="footer" />

    <component
      :is="'amp-next-page'"
      v-if="nextPages"
    >
      <component
        :is="'script'"
        type="application/json"
        data-amp-keep
        v-html="JSON.stringify(nextPages)"
      >
      </component>
    </component>

    <TheFooterContainer />
  </div>
</template>

<script setup lang="ts">
  import TheHeader from '~/components/TheHeader.amp/TheHeader.amp.vue';
  import TheSidebarContainer from '~/containers/TheSidebarContainer.amp.vue';
  import TheFooterContainer from '~/containers/TheFooterContainer.amp.vue';
  import { normalizeRecipe } from '~/libs/normalizeRecipe';
  import { ARTICLE_IMAGE_SIZES } from '~/constants/imageSizes';
  import { getImagesBySizes } from '@devhacker/shared/utils/image';
  import { isAnyRecipePage } from '~/utils/router';
  import getSlugFromUrl from '~/utils/getSlugFromUrl';
  import { useAmpAttributes } from '@devhacker/shared/composables/useAmpAttributes';
  import { useAmpCustomElement } from '@devhacker/shared/composables/useAmpCustomElement';

  useAmpAttributes();
  useAmpCustomElement('amp-next-page');

  const nextPages = ref([]);
  const isRecipe = ref(isAnyRecipePage(useRoute().name));

  const { data: items } = await useAsyncData(async () => {
    try {
      if (isRecipe.value) {
        const response = await useNuxtApp().$wordpressApi.fetchRecipes(1, '/recipes', 40);
        return response.recipes.map(normalizeRecipe);
      }

      const response = await useNuxtApp().$wordpressApi.fetchArticles(useRoute(), 1, 40);
      return response.data.items;
    } catch (e) {
      console.error('Error in default.amp.vue:', e);
    }
    return [];
  });

  const pageSlug = getSlugFromUrl(useRoute().fullPath);

  const getImage = (defaultImg: IArticleImg[], img: IArticleImg[] | []) => {
    const cover = getImagesBySizes([ARTICLE_IMAGE_SIZES.L.width], img);
    if (cover.length) {
      return cover[0].url;
    }

    const defaultCover = getImagesBySizes([ARTICLE_IMAGE_SIZES.L.width], defaultImg);
    if (defaultCover.length) {
      return defaultCover[0].url;
    }

    return '';
  };

  if (isRecipe.value) {
    nextPages.value = items.value?.reduce(
      (acc: Array<{ title: string; url: string; image: string }>, article: Recipe) => {
        const slugArticle = `${getSlugFromUrl(article.url)}amp/`;

        if (pageSlug !== slugArticle) {
          acc.push({
            title: article.title,
            url: `${article.url}amp/`,
            image: getImage(article.images, []),
          });
        }
        return acc;
      },
      [],
    );
  } else {
    nextPages.value = items.value?.reduce(
      (acc: Array<{ title: string; url: string; image: string }>, article: IArticle) => {
        const slugArticle = `${getSlugFromUrl(article.url)}amp/`;

        if (!article.adsToken && pageSlug !== slugArticle && !article.teaserUrl) {
          acc.push({
            title: article.title,
            url: `${article.url}amp/`,
            image: getImage(article.defaultImg, article.img),
          });
        }
        return acc;
      },
      [],
    );
  }
</script>

<style lang="scss" scoped>
  :deep(.amp-next-page-links) {
    display: none;
  }
</style>
