<template>
  <component
    :is="'amp-sidebar'"
    id="menuSidebar"
    class="sidebar"
    layout="nodisplay"
  >
    <div class="sidebar__header">
      <button
        class="sidebar__close"
        on="tap:menuSidebar.toggle"
      >
        <SvgAsset :path="menuCloseBlack" />
      </button>
      <a
        class="sidebar__logo-wrapper"
        href="/"
        :data-vars-link-href="$isAMP ? '/' : undefined"
      >
        <!-- Logo -->
        <SvgAsset
          v-if="isHealthPath"
          class="sidebar__logo"
          :path="healthLogoName"
        />
        <SvgAsset
          v-else
          class="sidebar__logo"
          :path="logoName"
        />
        <span
          v-if="isHealthPath"
          class="sidebar__health"
        >
          ЗДОРОВЬЕ
        </span>
      </a>
    </div>
    <nav class="sidebar__nav">
      <NavMenuCategories :burger-menu-items="burgerMenuItems" />
      <NavMenuSocials
        :social-title="socialTitle"
        :social-items="socialItems"
      />
    </nav>
  </component>
</template>

<script lang="ts">
  // Сайдбар в амп версии статьи. Для управления видимостью сайдбара
  // используется тег с атрибутом `on="tap:menuSidebar.toggle"`.
  // При клике на этот элемент будет показываться/скрываться сайдбар.

  import NavMenuCategories from '~/components/NavMenu/NavMenuCategories.vue';
  import NavMenuSocials from '~/components/NavMenu/NavMenuSocials.vue';
  import { isHealthPath } from '~/utils/router';
  import { IS_NEW_YEAR_LOGO } from '~/constants';
  import { useMenusStore } from '~/store/menus';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';

  import newYearLogoBlack from '~/assets/images/new-year-logo-black.svg';
  import healthLogoBlack from '~/assets/images/health-logo-black.svg';
  import lhLogoBlack from '~/assets/images/lh-logo-black.svg';
  import menuCloseBlack from '~/assets/images/menu-close-black.svg';
  import { useAmpCustomElement } from '@devhacker/shared/composables/useAmpCustomElement';

  // main menu
  export default defineNuxtComponent({
    name: 'TheSidebarContainerAmp',

    components: {
      NavMenuCategories,
      NavMenuSocials,
      SvgAsset,
    },

    setup() {
      useAmpCustomElement('amp-sidebar');
    },

    computed: {
      healthLogoName(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoBlack : healthLogoBlack;
      },

      logoName(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoBlack : lhLogoBlack;
      },

      menuCloseBlack: () => menuCloseBlack,

      burgerMenuItems() {
        // Check menus api and type Array<MenuItem>
        return useMenusStore().burgerMenuItems;
      },
      socialNetwork(): any {
        return useMenusStore().menus.burgerSubs;
      },
      socialTitle(): string {
        return this.socialNetwork?.title || 'Мы в соцсетях';
      },
      socialItems(): Array<MenuItemWithIcon> {
        return this.socialNetwork?.items;
      },
      isHealthPath(): boolean {
        return isHealthPath(useRoute().path);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .sidebar {
    background: white;
    width: 100vw;
    max-width: 320px;
    position: relative;
    padding-top: 15px;
    padding-bottom: 8px;
    overflow: hidden;
    &__nav {
      @include padding-y(8px);

      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__header {
      display: flex;
      padding: 0 0 8px;
      border-bottom: 1px solid #e7e7e7;
    }

    &__logo {
      width: 110px;
      height: 30px;
      margin-left: 10px;

      &-wrapper {
        margin-top: 5px;
        display: flex;
        text-decoration: none;
      }
    }

    &__close {
      margin-top: 10px;
      margin-left: 10px;
      background: none;
      border: none;
      outline: none;

      img {
        height: 24px;
        width: 24px;
      }
    }

    &__health {
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #000;
      font-family: $secondFont;
      display: flex;
      align-items: center;
      margin-left: 16px;

      &::before {
        content: '';
        width: 1px;
        background-color: #000;
        position: absolute;
        height: 24px;
        left: -6px;
        @include tablet {
          left: -8px;
        }
      }
    }
  }
</style>
<style lang="scss">
  .amp-sidebar-mask {
    background-color: rgba(0, 0, 0, 0.8);
  }
  // автоматически добавляется амповским компонентом
  .sidebar__nav ~ div {
    display: none;
  }
</style>
