<template>
  <!-- нужно для того, чтобы header не дублировался в статьях ampNextPage -->
  <header
    class="header"
    next-page-hide
  >
    <button
      class="burger"
      on="tap:menuSidebar.toggle"
      role="button"
      tabindex="1"
    >
      <SvgAsset :path="iconMenu" />
    </button>

    <div class="logo">
      <!-- !ЛОГО -->
      <a
        v-if="isHealthPath"
        class="logo__image"
        href="/"
        :data-vars-link-href="$isAMP ? getAnalyticsLink('/') : undefined"
      >
        <SvgAsset :path="healthLogoName" />
      </a>
      <a
        v-else
        class="logo__image"
        href="/"
        :data-vars-link-href="$isAMP ? getAnalyticsLink('/') : undefined"
      >
        <SvgAsset :path="logoName" />
      </a>
      <a
        v-if="isHealthPath"
        class="logo__health"
        href="/health/"
        :data-vars-link-href="$isAMP ? getAnalyticsLink('/health/') : undefined"
      >
        ЗДОРОВЬЕ
      </a>
    </div>
  </header>
</template>

<script lang="ts">
  import { isHealthPath } from '~/utils/router';
  import { getFullUrl } from '~/utils';
  import { IS_NEW_YEAR_LOGO } from '~/constants';
  import newYearLogoWhitePath from '~/assets/images/new-year-logo-white.svg';
  import healthLogoWhitePath from '~/assets/images/health-logo-white.svg';
  import lhLogoWhite from '~/assets/images/lh-logo-white.svg';
  import iconMenu from '~/assets/images/icon-menu.svg';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';

  export default defineNuxtComponent({
    name: 'TheHeaderAmp',

    components: {
      SvgAsset,
    },

    computed: {
      healthLogoName(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoWhitePath : healthLogoWhitePath;
      },

      logoName(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoWhitePath : lhLogoWhite;
      },

      isHealthPath(): boolean {
        return isHealthPath(this.$route.path);
      },
      iconMenu: () => iconMenu,
    },

    methods: {
      getAnalyticsLink(link: string): string {
        return getFullUrl(link);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding-right: 40px;
    padding-left: 16px;

    background-color: var(--main-color);
  }
  .burger {
    width: 24px;
    height: 24px;
    display: block;
    box-shadow: none;
    outline: none;
    background: none;
    border: none;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .logo {
    margin: 0 auto;
    display: flex;
    align-items: center;

    img {
      width: 112px;
      height: 20px;
    }

    &__health {
      position: relative;
      text-decoration: none;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      font-family: $secondFont;
      margin: -4px 0 0 4px;

      &::before {
        content: '';
        width: 1px;
        background-color: #fff;
        position: absolute;
        height: 100%;
        left: -8px;
      }
    }
  }
</style>
